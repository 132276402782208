import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const breakpoints = {
  xxs: 0,
  xs: 320,
  sm: 640,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

const IMAGE_CDN_BASE_URL = "https://d1s0vf9xbsii9u.cloudfront.net/babel/";

const imageSizes = {
  xxs: "320x320",
  xs: "320x320",
  sm: "640x640",
  md: "768x768",
  lg: "992x992",
  xl: "1200x1200",
  xxl: "1600x1600",
};

const getImageUrl = (image, size) =>
  image ? `${IMAGE_CDN_BASE_URL}fit-in/${size}/${image}` : "";

const ResponsiveImage = memo(
  ({ src, alt, sizes, maxWidths, fluidImage, customStyles, ...props }) => {
    const imageSrcSet = Object.keys(breakpoints)
      .map((key) => `${getImageUrl(src, imageSizes[key])} ${breakpoints[key]}w`)
      .join(", ");

    return (
      <StyledImg
        src={getImageUrl(src, imageSizes.xxs)}
        srcSet={imageSrcSet}
        sizes={sizes}
        alt={alt}
        maxWidths={maxWidths}
        fluidImage={fluidImage}
        customStyles={customStyles}
        {...props}
      />
    );
  }
);

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  maxWidths: PropTypes.shape({
    xxs: PropTypes.string,
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    xxl: PropTypes.string,
  }),
  fluidImage: PropTypes.bool,
  customStyles: PropTypes.object,
};

ResponsiveImage.defaultProps = {
  sizes: "(max-width: 991px) 100vw, 100vw",
  maxWidths: {
    xxs: "800px",
    xs: "800px",
    sm: "800px",
    md: "800px",
    lg: "800px",
    xl: "800px",
    xxl: "800px",
  },
  fluidImage: false,
  customStyles: {},
};

const StyledImg = styled.img`
  width: ${(props) => (props.customStyles.width ? props.customStyles.width : props.fluidImage ? "100%" : "auto")};
  height: ${(props) => (props.customStyles.height ? props.customStyles.height : props.fluidImage ? "auto" : "100%")};
  /* margin: 0 auto; */
  /* max-width: ${(props) =>
    props.fluidImage ? "100%" : props.maxWidths?.xxs || "800px"}; */
  display: block;
  ${(props) => props.customStyles && { ...props.customStyles }};
  @media (min-width: ${breakpoints.xs}px) {
    max-width: ${(props) =>
      props.fluidImage ? "100%" : props.maxWidths?.xs || "800px"};
  }
  @media (min-width: ${breakpoints.sm}px) {
    max-width: ${(props) =>
      props.fluidImage ? "100%" : props.maxWidths?.sm || "800px"};
  }
  @media (min-width: ${breakpoints.md}px) {
    max-width: ${(props) =>
      props.fluidImage ? "100%" : props.maxWidths?.md || "800px"};
  }
  @media (min-width: ${breakpoints.lg}px) {
    max-width: ${(props) =>
      props.fluidImage ? "100%" : props.maxWidths?.lg || "800px"};
  }
  @media (min-width: ${breakpoints.xl}px) {
    max-width: ${(props) =>
      props.fluidImage ? "100%" : props.maxWidths?.xl || "800px"};
  }
  @media (min-width: ${breakpoints.xxl}px) {
    max-width: ${(props) =>
      props.fluidImage ? "100%" : props.maxWidths?.xxl || "800px"};
  }
`;

export default ResponsiveImage;
